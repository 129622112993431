import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Map from "../../assets/indaimap.png";

const IndiaBanner = () => {
  return (
    <Box
      sx={{
        fontFamily: 'monospace',
        height: '110vh', // Full viewport height
        paddingTop: '2rem',
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack content vertically
        textAlign: 'center', // Center the text inside
        backgroundImage: `url(${Map})`, // Correctly reference the imported image
        backgroundSize: 'cover', // Ensure background image covers entire container
        backgroundPosition: 'center', // Center the background image
        color: '#fff', // White text color for contrast
        px: 2, // Padding for small screens
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2, // Ensures content is above the overlay
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 600 }}>
        20 Lacs+ Fintech Professionals Network
        </Typography>
        <Typography variant="h5">
        Gain access to an expansive talent pool, ready to meet your unique hiring needs.
        </Typography>
      </Container>
    </Box>
  );
};

export default IndiaBanner;
