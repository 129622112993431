import React from "react";
import ResponsiveAppBar from "../../components/NavigationBar/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Footer from "../../components/Footer/Footer";

const AboutUs = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ bgcolor: "#f9f9f9", py: 4 }}>
        <Container
          maxWidth="lg"
          sx={{ textAlign: "center", marginTop: "15px" }}
        >
          <Typography
            variant="h2"
            gutterBottom
            color="#603890"
            mb={4}
            sx={{ fontWeight: 500 }}
          >
            About Us
          </Typography>

          <Grid container spacing={3} sx={{ textAlign: "justify" }}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                At Fintech Talents, we redefine recruitment for the Financial
                Technology sector by delivering innovative, efficient, and
                customized hiring solutions. As India's first exclusive
                recruitment company for Fintech organizations, we have built a
                reputation as a trusted partner for leading fintech companies
                across India
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                Our team of 160+ expert recruiters, including 40+ Talent
                Acquisition Managers specialized in fintech hiring, leverages a
                vast database of 20 Lacs+ skilled fintech professionals to
                connect businesses with exceptional talent that drives
                innovation, growth, and long-term success
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                From sourcing and assessment to onboarding, we ensure candidates
                align with your technical needs and organizational culture. By
                combining cutting-edge tools with deep industry insights, Fintech
                Talents empowers organizations to thrive in an ever-evolving
                digital financial landscape.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                In just three years, we have become a trusted recruitment
                partner for 100+ top fintech companies, delivering tailored
                solutions that power the industry’s future. Together, we enable
                effortless scaling, operational excellence, and business growth.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default AboutUs;
